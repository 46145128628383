* {
    box-sizing: border-box; 
    margin: 0
}

body {
    margin: 0;
}

.nav {
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;
}

.site-title {
    font-size: 2rem;
}

.nav ul {
    margin: 0;
    list-style: none;
    display: flex;
    gap: 2rem;
    padding: 0 1rem; 
}

.nav a {
    color: inherit;
    text-decoration: none;
}


.nav li.active {
    background-color: grey;  
    padding: 0 1rem; 
}

.nav li:hover {
    background-color: #777;   
}
